<template>
  <ListSelfpaids/>
</template>

<script>
import ListSelfpaids from "@core/layouts/components/admin-components/ListSelfpaids";

export default {
  name: "AllSelfpaid",

  components: {
    ListSelfpaids
  }
}
</script>

<style scoped>

</style>